import React, { useEffect, useState } from "react";
import HomePage from "./pages/home";
import "./App.css";

function App() {
  const [mb, setMb] = useState<boolean>(false);
  useEffect(() => {
    resize()
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const resize = () => {
    setMb(window.innerWidth < 920);
  };
  return <div className="App">
    <HomePage mb={mb}/>
  </div>;
}

export default App;
