import React from "react";
import { Card, Typography, Flex, Empty } from "react-vant";
import { ParamsProps, TableDataProps } from "../../../type/common";

const WrapperStyle: React.CSSProperties = {
  padding: "20px",
  textAlign: "left",
};
function MTable({
  tableData,
  params,
}: {
  tableData: TableDataProps[];
  params: ParamsProps;
}) {
  return (
    <div style={WrapperStyle}>
      {!tableData.length ? <Empty description="暂无数据" /> : null}
      {tableData.map((e, i) => (
        <Card round key={i} style={{ marginBottom: "20px" }}>
          <Card.Body>
            <Flex wrap={"wrap"}>
              <Flex.Item span={24} style={{ marginBottom: "10px" }}>
                <Typography.Text strong style={{ marginRight: "6px" }}>
                  层级:{" "}
                </Typography.Text>
                <Typography.Text> {e.layer}</Typography.Text>
              </Flex.Item>
              <Flex.Item span={24} style={{ marginBottom: "10px" }}>
                <Typography.Text strong style={{ marginRight: "6px" }}>
                激活时间:{" "}
                </Typography.Text>
                <Typography.Text> {e.create_time}</Typography.Text>
              </Flex.Item>
              <Flex.Item span={24}>
                <Typography.Text strong style={{ marginRight: "6px" }}>
                  {" "}
                  地址:{" "}
                </Typography.Text>
                <div>
                  <Typography.Text
                    style={{ wordBreak: " break-all", marginTop: "5px" }}
                  >
                    <a
                      href={
                        "?address=" +
                        e.address +
                        "&begin=" +
                        params.begin +
                        "&end=" +
                        params.end
                      }
                    >
                      {e.address}
                    </a>{" "}
                  </Typography.Text>
                </div>
              </Flex.Item>
            </Flex>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
}

export default React.memo(MTable);
