import { Spin } from "antd";
import { ParamsProps, TableDataProps } from "../../type/common";
import MSearchBar from "./mComponents/SearchBar";
import MTable from "./mComponents/Table";

type Props = {
  query: (params: ParamsProps) => void;
  tableData: TableDataProps[];
  params: ParamsProps;
  loading: boolean;
};
export default function MHomePage({
  query,
  tableData,
  params,
  loading,
}: Props) {
  const onSearch = (params: ParamsProps) => {
    query(params);
  };
  return (
    <>
      <MSearchBar onSearch={onSearch} params={params} />
      <Spin spinning={loading}>
        <MTable tableData={tableData} params={params} />
      </Spin>
    </>
  );
}
