import React from "react";
import SearchBar from "./components/SearchBar";
import Table from "./components/Table";
import { ParamsProps, TableDataProps } from "../../type/common";
import { Spin } from "antd";

const contentStyle: React.CSSProperties = {
  width: "80%",
  margin: "auto",
  padding: "80px 0 0",
};

type Props = {
  query: (params: ParamsProps) => void;
  tableData: TableDataProps[];
  params: ParamsProps;
  loading: boolean
};
export default class PHomePage extends React.Component<Props> {
  onSearch(params: ParamsProps) {
    this.props.query(params);
  }
  render(): React.ReactNode {
    return (
      <div style={contentStyle}>
        <SearchBar onSearch={(params) => this.onSearch(params)} params={this.props.params} />
        <Spin spinning={this.props.loading}>
          <Table tableData={this.props.tableData} searchParams={this.props.params}/>
        </Spin>
      </div>
    );
  }
}
