import { Table as ATable } from "antd";
import { ParamsProps, TableDataProps } from "../../../type/common";
import { memo, useEffect, useState } from "react";
import type { ColumnsType } from 'antd/es/table';

const warpperStyle: React.CSSProperties = {
  marginTop: "40px",
};

type PropsType = {
  tableData: TableDataProps[];
  searchParams: ParamsProps;
};


 function Table({ tableData, searchParams }: PropsType) {
  const [columns, setColumns] = useState<ColumnsType<TableDataProps>>([]);
  useEffect(() => {
    setColumns([
      {
        title: "层级",
        dataIndex: "layer",
        key: "layer",
        width: 100,
      },
      {
        title: "地址",
        dataIndex: "address",
        key: "address",
        render: (value: any, row: TableDataProps) => {
          return (
          <a href={"?address=" + row.address +"&begin=" + searchParams.begin + "&end="+searchParams.end}>{value}</a>
        )},
      },
      {
        title: "激活时间",
        dataIndex: "create_time",
        key: "create_time",
        width: 200,
      },
    ])
  },[searchParams])
  return (
    <div style={warpperStyle}>
      <ATable
        dataSource={tableData}
        rowKey="create_time"
        columns={columns}
        bordered
        scroll={{ y: 500 }}
        pagination={false}
      />
    </div>
  );
}

export default memo(Table)