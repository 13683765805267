import React, { useEffect } from "react";
import { Calendar, Input, Button, Form } from "react-vant";
import dayjs from "dayjs";
import { ParamsProps } from "../../../type/common";

function MSearchBar({
  onSearch,
  params,
}: {
  onSearch: (ps: ParamsProps) => void;
  params: ParamsProps;
}) {
  const [form] = Form.useForm();
  const defaultDate: Date[] = [
    new Date(
      dayjs().add(-1, "week").startOf("week").add(1, "day").format("YYYY-MM-DD")
    ),
    new Date(
      dayjs().add(-1, "week").endOf("week").add(1, "day").format("YYYY-MM-DD")
    ),
  ];
  // const defaultDateNumber: number[] = [
  //   Math.ceil(
  //     Date.parse(
  //       new Date(
  //         dayjs().add(-1, "week").startOf("week").add(1, "day").valueOf()
  //       ).toDateString()
  //     ) / 1000
  //   ),
  //   Math.ceil(
  //     Date.parse(
  //       new Date(
  //         dayjs().add(-1, "week").endOf("week").add(1, "day").valueOf()
  //       ).toDateString()
  //     ) / 1000
  //   ),
  // ];

  // const [state, setState] = useState<ParamsProps>({
  //   addr: params.addr,
  //   begin: defaultDateNumber[0],
  //   end: defaultDateNumber[1],
  // });

  useEffect(() => {
    if (params.addr) {
      form.setFieldValue("addr", params.addr);
    }
    if (params.begin && params.end) {
      form.setFieldValue("dateRange", [
        new Date(params.begin * 1000),
        new Date(params.end * 1000),
      ]);
    } else {
      form.setFieldValue("dateRange", defaultDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const onFinish = (values: any) => {
    console.log("onFinish", values);
    const {addr, dateRange} = values
    onSearch({
      addr,
      begin: Math.ceil(Date.parse(new Date(dateRange[0]).toDateString()) / 1000),
      end: Math.ceil(Date.parse(new Date(dateRange[1]).toDateString()) / 1000),
    });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      footer={
        <div style={{ margin: "16px 16px 0" }}>
          <Button round nativeType="submit" type="primary" block>
            查询
          </Button>
        </div>
      }
    >
      <Form.Item
        name="dateRange"
        label="日期范围"
        rules={[{ required: true, message: "请选择日期范围！" }]}
        onClick={(_, action) => {
          if (!action) return;
          action.current?.open();
        }}
      >
        <Calendar
          type="range"
          minDate={new Date("2020-01-01")}
          // value={defaultDate}
          onConfirm={(val: any) => {
            form.setFieldValue("dateRange", val);
            // setState({
            //   addr: state.addr,
            //   begin: Math.ceil(Date.parse(val[0]) / 1000),
            //   end: Math.ceil(Date.parse(val[1]) / 1000),
            // });
          }}
        >
          {(val: any, actions) => {
            // console.log("val", val);
            return val
              ? val.map((el: Date) => el.toLocaleDateString()).join("~")
              : "请选择日期";
          }}
        </Calendar>
      </Form.Item>
      <Form.Item
        name="addr"
        label="查询地址"
        rules={[{ required: true, message: "请输入查询地址！" }]}
      >
        <Input
          // value={state.addr}
          // onChange={(addr) => setState({ ...state, addr })}
          placeholder="请输入查询地址"
        />
      </Form.Item>
    </Form>
  );
}

export default React.memo(MSearchBar);
