import React from "react";
import PHomePage from "./Pindex";
import MHomePage from "./mIndex";
import { ParamsProps, TableDataProps } from "../../type/common";
import getTableData from "../../api/getTableData";

type StateProps = {
  tableData: TableDataProps[];
  params: ParamsProps;
  loading: boolean;
};
type Props = {
  mb: boolean;
};
class HomePage extends React.Component<Props> {
  state: StateProps;
  constructor(props: Props) {
    super(props);
    this.state = {
      tableData: [],
      loading: true,
      params: {
        addr: "",
        begin: 0,
        end: 0,
      },
    };
  }
  componentDidMount(): void {
    const search = new URLSearchParams(window.location.search);
    const addr = search.get("address");
    const begin: number = Number(search.get("begin"));
    const end: number = Number(search.get("end"));
    if (addr && begin && end) {
      this.query({
        addr: addr || "",
        begin: begin,
        end: end,
      });
    }else {
      this.setState({ loading: false });
    }
  }

  async query(params: ParamsProps) {
    this.setState({ loading: true });
    const tableData = await getTableData({ ...params });
    this.setState({
      tableData,
      params,
      loading: false,
    });
  }

  toSearch(params: ParamsProps) {
    // console.log("toSearch", params);
    window.location.search = `?address=${params.addr}&end=${params.end}&begin=${params.begin}`;
  }

  render(): React.ReactNode {
    return (
      <div className="App">
        {!this.props.mb ? (
          <PHomePage
            tableData={this.state.tableData}
            params={this.state.params}
            loading={this.state.loading}
            query={(params) => this.toSearch(params)}
          />
        ) : (
          <MHomePage
            tableData={this.state.tableData}
            params={this.state.params}
            loading={this.state.loading}
            query={(params) => this.toSearch(params)}
          />
        )}
      </div>
    );
  }
}
export default HomePage;
