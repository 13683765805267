import axios from "axios";
import { ParamsProps } from "../type/common";
import dayjs from "dayjs"


export default function getTableData(params: ParamsProps) {
  // console.log("Dates before",new Date(params.begin * 1000), new Date(params.end))
  params.end = Math.ceil(Date.parse(new Date(dayjs(params.end * 1000).add(1,'day').valueOf()).toDateString())/1000)
  // console.log("Dates after",new Date(params.begin * 1000), new Date(params.end* 1000))
  return axios({
    method: "get",
    url: "https://gapi.runbit.io/api/v1/game/getChild",
    params
  }).then((res) => {
    return res.data.data.list

  })
}