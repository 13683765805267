import { Input, Form, DatePicker, Button } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { ParamsProps } from "../../../type/common";
import { useEffect, useRef } from "react";
const { RangePicker } = DatePicker;
dayjs.extend(customParseFormat);

type FieldType = {
  dateRange?: string;
  addr?: string;
};

export default function SearchBar({
  onSearch,
  params,
}: {
  onSearch: (params: ParamsProps) => void;
  params: ParamsProps;
}) {
  const form = useRef(null);
  useEffect(() => {
    if (form.current) {
      const r: any = form.current;
      if (params.addr) {
        r.setFieldsValue({
          addr: params.addr,
        });
      }
      if (params.end && params.begin) {
        r.setFieldsValue({
          dateRange: [dayjs(params.begin * 1000), dayjs(params.end * 1000)],
        });
      }
    
    }
  }, [params]);
  
  const onFinish = (values: any) => {
    console.log("Success:", values);
    onSearch({
      addr: values.addr,
      begin: Math.ceil(
        Date.parse(new Date(values.dateRange[0]).toDateString()) / 1000
      ),
      end: Math.ceil(Date.parse(new Date(values.dateRange[1]).toDateString()) / 1000),
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Form
        ref={form}
        name="basic"
        layout={"inline"}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          addr: "",
          dateRange: [
            dayjs().add(-1, "week").startOf("week").add(1, "day"),
            dayjs().add(-1, "week").endOf("week").add(1, "day"),
          ],
        }}
      >
        <Form.Item<FieldType>
          label="日期范围"
          name="dateRange"
          rules={[{ required: true, message: "请选择日期范围!" }]}
        >
          <RangePicker format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item<FieldType>
          label="查询地址"
          name="addr"
          rules={[{ required: true, message: "请输入查询地址!" }]}
        >
          <Input placeholder="请输入查询地址" />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          <Button type="primary" htmlType="submit">
            查询
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
